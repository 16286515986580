.container {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
}

.info_group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.name {
    margin-right: 16px;
}

.title_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.email {
    font-family: var(--font-regular);
    font-size: 14px;
    color: darkgray;
}

.show_info {
    color: #56d6bf;
    text-decoration: none;
    font-family: var(--font-bold);
    font-size: 16px;
    background-color: transparent;
    border-style: none;
}

#coaching_done {
    color: darkgray;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
}

.group_name {
    font-family: var(--font-bold);
    color: #56d6bf;
    background-color: #ecf7f5;
    padding: 6px 10px;
    font-size: 14px;
    margin-left: 24px;
    border-radius: 6px;
}

.coaching_group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.progress_group {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
}

.coaching_progress::-webkit-progress-bar {
    background: #f7fafa;
    border-radius: 4px;
    box-shadow: inset 3px 3px 10px #ccc;
}
.coaching_progress::-webkit-progress-value {
    border-radius: 4px;
    background: #56d6bf;
}