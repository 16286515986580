.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #e8fcf9;
    margin-bottom: 14px;
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
}

.question_box {
    display: flex;
}

.quetion_text {
    margin-right: 16px;
    font-family: var(--font-regular);
    color: black;
    font-size: 16px;
}

.check_box {
    width: 24px;
    height: 24px;
    margin-left: 24px;
    accent-color: #56d6bf;
}

input[type="checkbox"]:disabled {
    background: red;
    color: red;
}

@media only screen and (max-device-width : 480px) {
    .quetion_text {
        font-size: 14px;
        text-align: left;
        margin-right: 4px;
        line-height: 150%;
    }

    .check_box {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        accent-color: #56d6bf;
    }

    .check_container {
        width: 100px;
    }

    .question_box {
        width: 80%;
    }

    .container {
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 0px;
    }
}