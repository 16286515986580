.container {
    display: flex;
    flex-direction: column;
}

.info_group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.name {
    margin-right: 10px;
    font-size: 16px;
}

.title_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.email {
    font-family: var(--font-regular);
    font-size: 12px;
    color: darkgray;
}

.show_info {
    color: #56d6bf;
    text-decoration: none;
    font-family: var(--font-bold);
    font-size: 16px;
    background-color: transparent;
    border-style: none;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
}

.check_box {
    width: 24px;
    height: 24px;
    margin-left: 24px;
    accent-color: #56d6bf;
}
