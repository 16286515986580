.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7fafa;
}

.container_inside {
    width: 700px;
    background-color: white;
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-right: 200px;
    margin-left: 200px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.coaching_box {
    display: flex;
    flex-direction: column;
}

.coaching_title {
    text-align: left;
    font-size: 22px;
    font-family: var(--font-extraBold);
    color: #56d6bf;
}

.coaching_subtitle {
    text-align: left;
    font-family: var(--font-bold);
    font-size: 18px;
    color: black;
    margin-top: 0px;
}

.coaching {
    white-space: pre;
    text-align: left;
    line-height: 180%;
    background-color: #e8fcf9;
    font-family: var(--font-regular);
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 10px;
    margin-bottom: 100px;
}

.coaching_in_progress {
    font-size: 22px;
    color: darkgray;
    margin-top: 100px;
    margin-bottom: 150px;
}

.coaching_group {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    justify-content: center;
}

.coaching_title_group {
    display: flex;
    flex-direction: column;
}

.coaching_hint {
    text-align: left;
    color: darkgray;
    font-size: 16px;
    margin-top: 100px;
}

.student_story {
    text-align: left;
    color: black;
    font-size: 16px;
    margin-top: 0px;
    padding: 20px;
    background-color: #f7fafa;
    border-radius: 10px;
    white-space: pre-wrap;
    line-height: 150%;
}

.coaching_write_hint {
    text-align: left;
    color: black;
    font-size: 16px;
    margin-top: 40px;
}

.coaching_input {
    width: 100%;
    height: 200px;
    margin-bottom: 32px;
    border-radius: 10px;
    border-style: solid;
    border-color: lightgray;
    font-family: var(--font-bold);
    outline-color: #56d6bf;
    font-size: 16px;
    padding: 16px;
    line-height: 180%;
}

.done_box {
    display: flex;
    justify-content: center;
}

.done_btn {
    margin-top: 40px;
    margin-bottom: 100px;
    width: 120px;
    height: 50px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}

.graph {
    margin-top: 32px;
}

.test_result_title {
    color: rgb(79, 79, 79);
}

.coaching_feedback {
    text-align: left;
    margin-top: 60px;
}

#coachingHistoryBox {
    margin-top: 0px;
}