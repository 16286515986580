.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container {
    padding: 16px 0px;
}

.active {
    text-align: right;
    font-family: var(--font-bold);
    color: rgb(61, 227, 205);
}

.coupon_info_group {
    display: flex;
    justify-content: right;
    align-items: center;
}

.test_class {
    margin-right: 16px;
    font-family: var(--font-bold);
    font-size: 18px;
    color: rgb(163, 163, 163);
}

.test_time {
    font-size: 16px;
    margin-right: 200px;
}

.result_btn {
    color: white;
    padding: 12px;
    border-radius: 10px;
    border-style: none;
    font-size: 16px;
    font-family: var(--font-bold);
    background-color: rgb(61, 227, 205);
}