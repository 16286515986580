.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container {
    padding: 16px 0px;
}

.active {
    text-align: right;
    font-family: var(--font-bold);
    color: rgb(61, 227, 205);
}

.deactive {
    text-align: right;
    font-family: var(--font-bold);
    color: rgb(255, 138, 138);
}

.code {
    text-align: right;
    font-family: var(--font-bold);
    font-size: 18px;
}

.coupon_info_group {
    display: flex;
    justify-content: right;
    align-items: center;
}

.test_class {
    margin-right: 16px;
    font-family: var(--font-bold);
    font-size: 18px;
    color: rgb(163, 163, 163);
}