.container {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
}

.info_group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.title_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.name {
    font-size: 14px;
    margin-right: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
}

.email {
    font-family: var(--font-bold);
    font-size: 12px;
    color: darkgray;
}

.action_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    font-size: 14px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
}

.main_group {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.main_tag {
    font-size: 10px;
    color: #56d6bf;
    background-color: #ecf7f5;
    padding: 6px 8px;
    border-radius: 6px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.tag_box {
    width: 60px;
    display: flex;
    margin: 0px;
    padding: 0px;
}

.action_btn_done {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    font-size: 14px;
    font-family: var(--font-bold);
    color: darkgray;
    background-color: rgb(243, 243, 243);
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}