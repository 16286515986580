.container {
    background-color: #f7fafa;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.container_inside {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.month_selector {
    width: 100px;
    height: 40px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 40px;
    font-family: var(--font-bold);
    font-size: 16px;
    color: black;
    outline-color: #56d6bf;
}

.year_selector {
    width: 120px;
    height: 40px;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 0px;
    margin-bottom: 40px;
    font-family: var(--font-bold);
    font-size: 16px;
    color: black;
    outline-color: #56d6bf;
}

.date_group {
    display: flex;
}

.empty_msg {
    margin-top: 80px;
    margin-bottom: 100px;
    color: lightgray;
}