.parent {
    display: flex;
}

.left {
    flex: 1;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.left_inside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.right {
    flex: 1;
    background-color: #56d6bf;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.brand_info {
    text-decoration: underline;
    margin: 0px 60px;
    margin-top: 32px;
    text-align: left;
    display: block;
    color: #56d6bf;
    font-family: var(--font-bold);
    font-size: 14px;
}

.main_input {
    display: block;
    width: 250px;
    margin-top: 16px;
    height: 40px;
    border-radius: 10px;
    border-color: #edf0f0;
    border-style: solid;
    outline-color: #56d6bf;
    border-width: 1px;
    padding-left: 10px;
    padding-right: 10px;
}

.main_input::placeholder {
    color: lightgray;
}

.main_button {
    display: block;
    width: 100%;
    margin-top: 16px;
    background-color: #56d6bf;
    border-radius: 10px;
    height: 40px;
    color: white;
    border-style: none;
    font-family: var(--font-bold);
    font-size: 16px;
}

button:hover {
    cursor: pointer;
}

.top {
    display: block;
    width: 100%;
    justify-content: left;
    align-items: left;
}

.middle {
    display: block;
    margin-top: 20px;
    margin-bottom: 32px;
}

.logo {
    height: 200px;
}

.title_1 {
    margin-bottom: 0px;
    font-family: var(--font-bold);
    font-size: 20px;
    text-align: left;
    color: black;
}

.title_2 {
    margin-bottom: 3px;
    font-family: var(--font-bold);
    font-size: 14px;
    text-align: left;
    color: darkgray;
}

.sign_up_hint {
    display: inline;
    margin-right: 10px;
    font-family: var(--font-regular);
    font-size: 14px;
    color: darkgray;
}

.sign_up_btn {
    font-family: var(--font-bold);
    font-size: 14px;
    color: #56d6bf;
    text-decoration: none;
    border-style: none;
    background-color: transparent;
}

.brand_1 {
    margin-top: 0px;
    font-family: var(--font-bold);
    font-size: 40px;
    color: white;
    text-align: left;
}

.modal {
    background-color: red;
}

.error_msg {
    font-size: 14px;
    color: rgb(255, 93, 93);
}

.social_btn {
    background-color: transparent;
    border-style: none;
    margin-top: 24px;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 32px;
}