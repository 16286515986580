.container {
    padding: 6px 0px;
}

.info_group {
    display: flex;
    justify-content: left;
    align-items: center;
}

.name {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.email {
    font-family: var(--font-bold);
    font-size: 12px;
    color: rgb(207, 207, 207);
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
}

.main_group {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.main_tag {
    font-size: 10px;
    color: #56d6bf;
    background-color: #ecf7f5;
    padding: 6px 8px;
    border-radius: 6px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.tag_box {
    width: 46px;
    display: flex;
    margin: 0px;
    padding: 0px;
}

.comment {
    display: block;
    text-align: left;
    font-size: 14px;
    margin: 6px 0px;
}