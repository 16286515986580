.parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.parent_inner {
    width: 800px;
    background-color: white;
    margin-bottom: 500px;
}

.cover {
    position: relative;
}

.cover_img {
    width: 100%;
}

.student_info {
    position: absolute;
    bottom: 140px;
    right: 140px;
    background-color: #EDF8F8;
    border-radius: 20px;
    border-style: none;
    padding: 16px 24px;
    padding-right: 60px;
}

.student_info p {
    text-align: left;
    font-size: 16px;
}

.student_1 p {
    font-size: 18px;
}

.student_1 {
    margin-bottom: 24px;
}

.student_2 {
    margin-bottom: 24px;
}

.download_btn {
    width: 300px;
    height: 80px;
    margin: 50px 0px;
    font-size: 20px;
    border-radius: 10px;
    border-style: none;
}

.text_box {
    display: flex;
    justify-content: center;
}

.top_banner {
    width: 100%;
}

.title_banner {
    width: 90%;
    margin-top: 42px;
    margin-bottom: 32px;
}

.top_banner_box {
    width: 100%;
    position: relative;
    text-align: center;
}

.title_banner_box {
    position: relative;
    text-align: center;
}

.only_graph_box {
    padding: 0px 160px;
}

.top_title {
    color: white;
    font-size: 28px;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title {
    position: absolute;
    font-size: 18px;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.coaching_text {
    width: 80%;
    font-size: 14px;
    margin-top: 80px;
    margin-bottom: 120px;
    text-align: justify;
    line-height: 150%;
    white-space: pre-wrap;
}

.resultText {
    font-size: 14px;
    white-space: pre-wrap;
    line-height: 180%;
    text-align: left;
    margin: 0px 100px;
    margin-bottom: 100px;
}

.profile {
    padding: 0px 180px;
}

.space {
    height: 100px;
}

.profile_title {
    margin-top: 60px;
    margin-bottom: 0px;
}

.page_inner {
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm gray;
}

.page[size="A4"] {  
    width: 21cm;
    height: 29.7cm; 
}

@media print {
    body, .page_inner {
        background: white;
        margin: 0;
        box-shadow: 0;
    }
}