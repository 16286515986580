.container {
    background-color: white;
    border-radius: 20px;
    padding-top: 32px;
    padding-bottom: 32px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.group_top {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    text-align: left;
    font-family: var(--font-bold);
    font-size: 18px;
    margin-bottom: 8px;
    color: block;
}

.action_btn {
    color: #56d6bf;
    background-color: white;
    border-style: none;
    font-family: var(--font-extraBold);
    font-size: 18px;
    cursor: pointer;
}

.info_box {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
}

.info_box_inside {
    display: flex;
    justify-content: space-between;
    padding-right: 168px;
}

.info_name {
    color: lightgray;
    font-family: var(--font-bold);
    font-size: 12px;
}

.hint {
    color: lightgray;
    font-family: var(--font-bold);
    font-size: 12px;
    margin-bottom: 0px;
    text-align: right;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 0px;
    margin-bottom: 0px;
}

.default_msg {
    color: lightgray;
    margin-top: 100px;
    margin-bottom: 80px;
}

.back_group {
    display: flex;
    align-items: center;
}

.back_arrow {
    color: darkgray;
    margin-right: 10px;
}

.back_group:hover {
    cursor: pointer;
}

.subtitle {
    color: lightgray;
    margin-top: 0px;
    font-size: 14px;
    text-align: left;
}