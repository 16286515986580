.container {
    background-color: rgb(250, 250, 250);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
}

.container_inside {
    justify-content: left;
    background-color: white;
    border-radius: 20px;
    padding: 50px 60px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.test_title_group {
    display: flex;
    align-items: center;
}

.test_title_group:hover {
    cursor: pointer;
}

.hint {
    color: rgb(170, 170, 170);
    font-size: 14px;
    line-height: 150%;
    margin: 100px 0px;
}