.title {
    text-align: left;
    font-family: var(--font-extraBold);
    color: #56d6bf;
    font-size: 22px;
}

.subtitle_box {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 0px;
}

.step {
    font-family: var(--font-extraBold);
    color: #56d6bf;
    font-size: 18px;
    margin-right: 10px;
}

.subtitle {
    text-align: left;
    font-family: var(--font-bold);
    color: black;
    font-size: 20px;
}

.desc {
    text-align: left;
    font-family: var(--font-regular);
    line-height: 160%;
    color: black;
    font-size: 14px;
    margin-bottom: 24px;
    white-space: pre-line;
}

.desc span {
    font-family: var(--font-bold);
    color: #ee6a6a;
}

.desc_detail {
    text-align: left;
    font-family: var(--font-bold);
    line-height: 160%;
    color: black;
    font-size: 16px;
    background-color: #e8fcf9;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 10px;
    white-space: pre-line;
}

.title_box {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
}

.option_box {
    margin-top: 8px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7fafa;
}

.container_inside {
    background-color: white;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.done_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 50px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}

.option_title {
    display: flex;
    justify-content: right;
    align-items: right;
    margin-right: 16px;
}

.level {
    font-family: var(--font-bold);
    color: black;
    font-size: 14px;
    margin-left: 16px;
    margin-top: 64px;
}

.done_box {
    display: flex;
    justify-content: right;
    align-items: right;
    margin-right: 8px;
    margin-bottom: 60px;
}

.container_middle {
    display: flex;
    flex-direction: row;
}

.float_board {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 16px;
    left: 0px;
    right: 0px;
    position: fixed;
    pointer-events: none;
}

.board_inside {
    background-color: white;
    border-radius: 20px;
    padding: 10px 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.choice_title {
    font-family: var(--font-extraBold);
    font-size: 14px;
    color: black;
    text-align: left;
    white-space: pre-line;
    line-height: 150%;
}

.choice_group {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.choice_level {
    font-family: var(--font-bold);
    font-size: 14px;
    color: darkgray;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
}

.choice {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: var(--font-bold);
    font-size: 18px;
    color: black;
    text-align: left;
    margin-left: 24px;
    width: 400px;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
}

.choice_group_inside {
    display: flex;
    flex-direction: column;
}

.choice_hint {
    border-radius: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 0px;
}

.title_group {
    display: flex;
}

@media only screen and (max-device-width : 480px) {
    .container_inside {
        padding-left: 16px;
        padding-right: 16px;
    }

    .option_title {
        margin-right: 2px;
    }

    .level {
        font-family: var(--font-bold);
        color: black;
        font-size: 10px;
        margin-left: 8px;
        margin-top: 64px;
    }

    .choice {
        margin-top: 6px;
        margin-bottom: 6px;
        font-size: 14px;
        margin-left: 16px;
        width: 70%;
    }

    .choice_title {
        font-size: 12px;
    }

    .choice_level {
        font-size: 12px;
    }

    .choice_hint {
        font-size: 12px;
    }

    .devider {
        margin: 8px 0px;
    }

    .board_inside {
        width: 80%;
    }

    .choice_group {
        justify-content: left;
        margin-left: 16px;
    }
}
