.container {
    background-color: white;
    border-radius: 20px;
    padding-top: 32px;
    padding-bottom: 32px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.group_top {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    text-align: left;
    font-family: var(--font-bold);
    font-size: 18px;
    color: block;
}

.action_btn {
    color: #56d6bf;
    background-color: white;
    border-style: none;
    font-family: var(--font-extraBold);
    font-size: 18px;
    cursor: pointer;
}

.default_msg {
    color: lightgray;
    margin-top: 100px;
    margin-bottom: 80px;
}

.teacher_hint {
    color: lightgray;
    font-size: 14px;
    line-height: 150%;
    text-align: left;
    margin: 0px 32px;
    margin-bottom: 16px;
}