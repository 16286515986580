.container {
    position: fixed;
    top:0; left: 0; bottom: 0; right: 0;
    background: rgba(0, 0, 0, 0.6);
}

.container_inside {
    border-radius: 20px;
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    padding-top: 16px;
}

.option_group {
    margin-top: 40px;
}

.option {
    margin-top: 10px;
    text-decoration: none;
    font-family: var(--font-bold);
    font-size: 18px;
    color: white;
    border-radius: 10px;
    background-color: #56d6bf;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.title {
    font-family: var(--font-bold);
    text-align: left;
    font-size: 16px;
    color: black;
}