.container {
    background-color: white;
    border-radius: 20px;
    padding: 32px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.title {
    text-align: left;
    margin-bottom: 40px;
}

.section {
    display: flex;
    align-items: center;
}

.option_title {
    font-size: 14px;
    color: lightgray;
    width: 100px;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
}

.option_content {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}