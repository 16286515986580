.container {
    background-color: #f7fafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
}

.container_inside {
    justify-content: left;
    background-color: white;
    border-radius: 20px;
    padding: 50px 80px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.input_group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 40px;
}

.input_box {
    width: 90%;
    height: 40px;
    margin-bottom: 16px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #edf0f0;
    font-family: var(--font-bold);
    font-size: 14px;
    color: black;
    outline-color: #56d6bf;
    padding-left: 16px;
    padding-right: 16px;
}

.done_btn {
    border-radius: 10px;
    padding: 10px 20px;
    background-color: #56d6bf;
    color: white;
    font-family: var(--font-bold);
    border-style: none;
    font-size: 16px;
    text-decoration: none;
}

.devider {
    margin-top: 60px;
    margin-bottom: 60px;
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
}

.social_btn {
    background-color: white;
    border-style: none;
}

.title {
    text-align: left;
    font-family: var(--font-bold);
    font-size: 20px;
    color: black;
}

.subtitle {
    text-align: left;
    font-family: var(--font-bold);
    font-size: 16px;
    color: darkgray;
}