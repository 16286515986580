.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7fafa;
}

.container_inside {
    width: 700px;
    background-color: white;
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-right: 200px;
    margin-left: 200px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.coaching_box {
    display: flex;
    flex-direction: column;
}

.coaching_title {
    text-align: left;
    font-size: 20px;
    font-family: var(--font-bold);
    margin: 60px 0px;
}

.coaching_subtitle {
    text-align: left;
    font-family: var(--font-bold);
    font-size: 18px;
    color: black;
    margin-top: 0px;
}

.coaching {
    white-space: pre-wrap;
    text-align: left;
    line-height: 180%;
    background-color: #e8fcf9;
    font-family: var(--font-regular);
    font-size: 14px;
    padding: 24px 32px;
    border-radius: 10px;
    margin-bottom: 80px;
}

.approve {
    margin-top: 40px;
    margin-bottom: 100px;
    margin-right: 10px;
    width: 120px;
    height: 50px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}

.reject {
    margin-top: 40px;
    margin-bottom: 100px;
    margin-left: 10px;
    width: 120px;
    height: 50px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: white;
    background-color: #f29292;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}

.feedback_btn {
    margin-top: 40px;
    margin-bottom: 80px;
    width: 120px;
    height: 50px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}

.feedback_group {
    margin-bottom: 80px;
}

.top_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.top_menu:hover {
    cursor: pointer;
}

.back_arrow {
    margin-right: 10px;
}

.story_box {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.story_title {
    text-align: left;
    margin-bottom: 10px;
}

.story {
    text-align: left;
    white-space: break-spaces;
    line-height: 150%;
    font-size: 14px;
    color: rgb(122, 122, 122);
    background-color: rgb(244, 244, 244);
    padding: 24px;
    border-radius: 10px;
}

.wait_coaching {
    margin: 100px 0px;
    margin-bottom: 200px;
}

.test_title {
    text-align: left;
    margin-bottom: 32px;
}

.test_result_title {
    color: rgb(98, 98, 98);
}

.coaching_input {
    width: 100%;
    height: 200px;
    margin-top: 40px;
    margin-bottom: 16px;
    border-radius: 10px;
    border-style: solid;
    border-color: lightgray;
    font-family: var(--font-bold);
    outline-color: #56d6bf;
    font-size: 16px;
    padding: 16px;
}

.coaching_input::placeholder {
    color: rgb(208, 208, 208);
}

.coaching_feedback {
    text-align: left;
    margin-top: 60px;
}

#coachingHistoryBox {
    margin-top: 0px;
}

.save_pdf_box {
    display: flex;
    padding-bottom: 16px;
    justify-content: right;
}

.save_pdf_button {
    padding: 12px 16px;
    font-size: 14px;
    font-family: var(--font-bold);
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    color: white;
}