.container {
    padding-top: 60px;
    padding-bottom: 100px;
    background-color: #f7fafa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.input_container {
    margin-top: 60px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 80px;
    border-radius: 20px;
    margin-bottom: 60px;
}

.title_box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 60px;
}

.title_box:hover {
    cursor: pointer;
}

.back_arrow {
    color: darkgray;
    padding-right: 8px;
}

.title {
    text-align: left;
    font-family: var(--font-bold);
    font-size: 20px;
    color: darkgray;
}

.input_group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.input_hint {
    width: 90px;
    margin-right: 32px;
    text-align: left;
    font-size: 14px;
}

.input_box {
    width: 100%;
    height: 40px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #edf0f0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-family: var(--font-bold);
    outline-color: #56d6bf;
    color: black;
}

input::placeholder {
    color: lightgray;
}

.signup {
    margin-top: 48px;
    margin-bottom: 24px;
    font-family: var(--font-bold);
    font-size: 18px;
    color: white;
    padding: 12px 18px;
    border-radius: 12px;
    background-color: #56d6bf;
    text-decoration: none;
    border-style: none;
}

.gender_group {
    display: flex;
    width: 100%;
    height: 34px;
}

.gender_btn_deactive {
    border-radius: 10px;
    border-style: none;
    background-color: #edf0f0;
    width: 50px;
    margin-right: 10px;
    font-size: 14px;
    font-family: var(--font-bold);
    color: rgb(146, 146, 146);
}

.gender_btn {
    border-radius: 10px;
    border-style: none;
    width: 50px;
    margin-right: 10px;
    font-size: 14px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
}

.date_picker {
    height: 30px;
    border-radius: 10px;
    border-style: solid;
    border-color: #edf0f0;
}

.date_picker_container {
    display: flex;
    justify-content: left;
}
