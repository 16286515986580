.container {
    background-color: #f7fafa;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.container_inside {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.title {
    display: inline;
    text-align: left;
    margin: 24px;
}

.num_coaching {
    display: inline;
    text-align: left;
    margin: 24px;
    color: #56d6bf;
}

.top_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}