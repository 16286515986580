.container {
    background-color: rgb(250, 250, 250);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
}

.container_inside {
    justify-content: left;
    background-color: white;
    border-radius: 20px;
    padding: 50px 100px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.back {
    display: flex;
    align-items: center;
}

.back:hover {
    cursor: pointer;
}

.back_arrow {
    margin-right: 10px;
}

.title {
    text-align: left;
    margin-top: 60px;
    color: rgb(185, 185, 185);
}

.desc {
    text-align: left;
    margin-top: 16px;
    font-size: 16px;
    color: rgb(72, 72, 72);
    margin-bottom: 50px;
}

.product_title {
    text-align: left;
    font-size: 18px;
    margin-bottom: 4px;
}

.product_desc {
    margin-top: 4px;
    text-align: left;
    line-height: 200%;
    font-size: 14px;
    color: rgb(187, 187, 187);
}

.money {
    font-size: 22px;
}

.active {
    background-color: rgb(247, 247, 247);
    margin-top: 16px;
    padding: 8px 24px;
    border-radius: 10px;
}

.do_btn {
    background-color: #60e2ca;
    font-family: var(--font-bold);
    font-size: 14px;
    color: white;
    border-radius: 10px;
    border-style: none;
    padding: 12px;
    margin: 16px 5px;
}

.coupon_btn {
    background-color: #aab9ef;
    font-family: var(--font-bold);
    font-size: 14px;
    color: white;
    border-radius: 10px;
    border-style: none;
    padding: 12px;
    margin: 16px 5px;
}

.price {
    font-size: 20px;
}

.top_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my_test_list {
    background-color: #60e2ca;
    height: 44px;
    padding: 0px 16px;
    font-family: var(--font-bold);
    color: white;
    border-radius: 10px;
    border-style: none;
}

.refund_title {
    font-size: 10px;
    color: rgb(125, 125, 125);
    margin-bottom: 6px;
}

.refund_subtitle {
    font-size: 10px;
    margin-top: 6px;
    color: rgb(125, 125, 125);
}

.refund_content {
    font-size: 10px;
    color: rgb(87, 87, 87);
}

.refund_group {
    margin-bottom: 40px;
    padding: 8px;
    border-radius: 10px;
    background-color: rgb(247, 247, 247);
}