.container {
    background-color: #f7fafa;
}

.top {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
}

.bottom {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 100px;
}

.title {
    color: black;
    font-family: var(--font-extraBold);
    font-size: 26px;
    text-align: left;
    margin-bottom: -10px;
}

.subtitle {
    color: lightgray;
    font-family: var(--font-bold);
    font-size: 14px;
    text-align: left;
    margin-bottom: 24px;
}

.card_container {
    display: flex;
}

.card {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 10px;
    padding-bottom: 16px;
    border-radius: 10px;
    margin-right: 16px;
    width: 250px;
    background-color: white;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
}

.card_title {
    text-align: left;
    color: black;
    font-family: var(--font-bold);
    font-size: 20px;
    margin-bottom: 0px;
}

.card_subtitle {
    text-align: left;
    color: darkgray;
    font-family: var(--font-regular);
    font-size: 14px;
    line-height: 150%;
}

.card_action {
    margin-top: 16px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: var(--font-bold);
    color: white;
    font-size: 14px;
    background-color: #56d6bf;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 6px;
}

.card_action_deactive {
    margin-top: 16px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: var(--font-bold);
    font-size: 14px;
    color: #56d6bf;
    background-color: #ecf7f5;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 6px;
}

.month {
    display: flex;
    font-size: 24px;
    font-family: var(--font-bold);
    color: darkgray;
    padding-top: 40px;
    padding-bottom: 32px;
}

.top_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;
}

.back_arrow {
    color: darkgray;
    padding-top: 40px;
    margin-bottom: 32px;
    padding-right: 16px;
}

.top_menu:hover {
    cursor: pointer;
}

.coaching_outside {
    display: flex;
}

.coaching_inside {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 16px 60px;
    padding: 32px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
    border-radius: 12px;
    justify-content: center;
}

.request_coaching_text { 
    margin: 0px;
    font-size: 16px;
    color: darkgray;
    white-space: break-spaces;
    text-align: left;
    line-height: 180%;
}

.request_coaching_btn {
    width: 120px;
    margin-top: 24px;
    height: 44px;
    color: white;
    font-family: var(--font-bold);
    font-size: 14px;
    border-style: none;
    background-color: #56d6bf;
    padding: 4px 10px;
    border-radius: 6px;
}

.request_coaching_btn_deactive {
    width: 120px;
    margin-top: 24px;
    height: 44px;
    color: rgb(157, 157, 157);
    font-family: var(--font-bold);
    font-size: 14px;
    border-style: none;
    background-color: #e0e0e0;
    padding: 4px 10px;
    border-radius: 6px;
}

.coaching_process_btn {
    width: 120px;
    margin-top: 24px;
    height: 44px;
    font-family: var(--font-bold);
    font-size: 14px;
    border-style: none;
    color: #56d6bf;
    background-color: #ecf7f5;
    padding: 4px 10px;
    border-radius: 6px;
}

.coaching_process_btn:hover {
    cursor: text;
}