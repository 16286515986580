.container {
    position: fixed;
    top:0; left: 0; bottom: 0; right: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
}

.container_inside {
    border-radius: 20px;
    z-index: 999;
    position: absolute;
    padding: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
}

.option {
    margin-top: 10px;
    text-decoration: none;
    font-family: var(--font-bold);
    font-size: 18px;
    color: white;
    border-radius: 10px;
    background-color: #56d6bf;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.title_box {
    margin-top: -10px;
    margin-bottom: 40px;
}

.modal_title {
    font-family: var(--font-bold);
    text-align: left;
    margin-bottom: -6px;
    font-size: 16px;
    color: black;
}

.cancel {
    margin-top: 16px;
    width: 100%;
    height: 40px;
    background-color: rgb(234, 233, 233);
    color: darkgray;
    font-family: var(--font-bold);
    font-size: 16px;
    border-radius: 10px;
    border-style: none;
}

.logout {
    margin-top: 16px;
    width: 100%;
    height: 40px;
    background-color: rgb(255, 228, 228);
    color: rgb(254, 121, 121);
    font-family: var(--font-bold);
    font-size: 16px;
    border-radius: 10px;
    border-style: none;
}