.container {
    position: fixed;
    top:0; left: 0; bottom: 0; right: 0;
    background: rgba(0, 0, 0, 0.6);
}

.container_inside {
    border-radius: 20px;
    z-index: 999;
    position: absolute;
    padding: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
}

.modal_title {
    font-family: var(--font-bold);
    text-align: left;
    font-size: 16px;
    line-height: 150%;
    color: black;
}

.email {
    width: 90%;
    height: 40px;
    margin-bottom: 16px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #edf0f0;
    font-family: var(--font-bold);
    font-size: 14px;
    color: black;
    outline-color: #56d6bf;
    padding-left: 16px;
    padding-right: 16px;
}

.invite_btn {
    margin-top: 24px;
    width: 120px;
    height: 50px;
    background-color: #56d6bf;
    color: white;
    font-family: var(--font-bold);
    font-size: 16px;
    border-radius: 10px;
    border-style: none;
}

.cancel_btn {
    margin-top: 24px;
    width: 120px;
    height: 50px;
    background-color: #edf0f0;
    color: rgb(146, 146, 146);
    font-family: var(--font-bold);
    font-size: 16px;
    border-radius: 10px;
    border-style: none;
}

.space {
    width: 10px;
}

.btn_group {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}

.scroll {
    height: 300px;
    overflow: auto;
}
