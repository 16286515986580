.parent {
    margin: 40px 0px;
}

.msg_box {
    display: flex;
    align-items: center;
}

.status_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    font-size: 14px;
    color: rgb(205, 205, 205);
}

.approve_status {
    font-size: 14px;
    display: inline;
    color: #56d6bf;
    background-color: #eafaf8;
    padding: 8px 12px;
    border-radius: 10px;
}

.reject_status {
    font-size: 14px;
    display: inline;
    color: #ed5757;
    background-color: #fcf1f1;
    padding: 8px 12px;
    border-radius: 10px;
}

.msg_title {
    font-size: 14px;
    color: rgb(183, 183, 183);
    width: 120px;
    text-align: left;
    margin-right: 32px;
}

.content {
    font-size: 14px;
    text-align: left;
    line-height: 150%;
}

.devider {
    margin-top: 40px;
    background: rgb(233, 233, 233);
    height: 1px;
    border: 0;
}