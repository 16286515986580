.title {
    text-align: left;
    font-family: var(--font-extraBold);
    color: #56d6bf;
    font-size: 22px;
    margin-right: 500px;
    margin-top: 60px;
}

.desc {
    text-align: left;
    font-family: var(--font-bold);
    font-size: 18px;
    margin-bottom: 80px;
    line-height: 150%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7fafa;
}

.container_inside {
    width: 700px;
    background-color: white;
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-right: 200px;
    margin-left: 200px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.done_btn {
    margin-top: 40px;
    margin-bottom: 100px;
    width: 120px;
    height: 50px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}

.graph {
    width: 100%;
    margin-bottom: 60px;
}

table {
    margin-bottom: 60px;
    width: 100%;
    font-family: var(--font-bold);
    font-size: 16px;
    color: black;
    table-layout: fixed;
    text-align: center;
}

.table_title {
    background-color: #e8fcf9;
}

table, td, th {
    border: 1px solid darkgray;
    border-collapse: collapse;
    border-color: #edf0f0;
}

th, td {
    padding: 16px;
}

td {
    font-size: 14px;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 0px;
    margin-bottom: 60px;
}

.prepare_coaching_approval {
    color: darkgray;
    margin-top: 100px;
    margin-bottom: 60px;
}

.approve {
    margin-bottom: 100px;
    width: 120px;
    height: 50px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}

.feedback_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    font-size: 14px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
    margin-bottom: 32px;
}

.feedback_group {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.desc {
    width: 100%;
    /* height: 100%; */
    margin-top: 16px;
    margin-bottom: 0px;
}

.desc_title {
    font-size: 20px;
    margin-top: 100px;
    text-align: left;
}

.desc_group {
    margin-bottom: 60px;
}