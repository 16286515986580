.container {
    display: flex;
    margin-left: 32px;
    margin-right: 32px;
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.container_inside {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.month {
    font-size: 24px;
    font-family: var(--font-bold);
    color: black;
    margin-right: 16px;
}

.status {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: var(--font-bold);
    height: 24px;
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    margin-top: 30px;
    border: 0;
}