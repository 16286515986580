.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f7fafa;
}

.top {
    display: flex;
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
}

.student_group {
    width: 200px;
    height: 120px;
    margin-right: 16px;
    background-color: white;
    border-style: none;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 32px;
    align-items: center;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
    text-decoration: none;
}

.student_btn {
    text-decoration: none;
    font-family: var(--font-bold);
    font-size: 18px;
    color: black;
}

.task_container {
    border-radius: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #56d6bf;
}

.test_title_group {
    display: flex;
    align-items: center;
    background-color: white;
    margin-top: 60px;
    margin-bottom: 0px;
    margin-left: 32px;
    margin-right: 32px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.test_title {
    margin-left: 16px;
    text-align: left;
    font-size: 18px;
    font-family: var(--font-bold);
}

.test_title_group:hover {
    cursor: pointer;
}

.test_bottom {
    height: 20px;
    background-color: white;
    margin-top: 0px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 100px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.invite_group {
    margin-left: 16px;
    margin-right: 16px;
    background-color: white;
    margin-top: 16px;
    margin-bottom: 16px;
    border-style: none;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 40px;
    align-items: center;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
    padding-bottom: 32px;
}

.invite_title {
    font-size: 16px;
    line-height: 150%;;
}

.invite_action_group {
    display: flex;
}

.invite_action {
    margin-left: 8px;
    margin-right: 8px;
    width: 70px;
    height: 34px;
    font-size: 14px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}

#reject {
    background-color: rgb(246, 158, 158);
}

.payment_group {
    background-color: white;
    margin: 0px 32px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.payment_hint {
    color: darkgray;
    margin-top: 40px;
    margin-bottom: 30px;
}

.payment_btn {
    margin-bottom: 100px;
    width: 120px;
    height: 50px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}