.container {
    background-color: #f7fafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
}

.container_inside {
    justify-content: left;
    background-color: white;
    border-radius: 20px;
    padding: 50px 100px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.back {
    display: flex;
    align-items: center;
}

.back:hover {
    cursor: pointer;
}

.back_arrow {
    margin-right: 10px;
}

.title {
    text-align: left;
    margin-top: 60px;
    color: rgb(185, 185, 185);
}

.product_title {
    text-align: left;
    font-size: 20px;
    margin-bottom: 16px;
}

.product_desc {
    margin-top: 16px;
    text-align: left;
    line-height: 200%;
    font-size: 14px;
    color: rgb(157, 157, 157);
}

.product_group {
    background-color: rgb(247, 247, 247);
    margin-top: 32px;
    padding: 16px 24px;
    border-radius: 10px;
}

.pay_title {
    margin-top: 80px;
    font-size: 20px;
    text-decoration: underline;
    text-decoration-color: #80ead7;
    text-decoration-thickness: 6px;
    margin-bottom: 24px;
}

.money {
    font-size: 22px;
}

.pay_btn {
    background-color: #60e2ca;
    font-family: var(--font-bold);
    font-size: 20px;
    color: white;
    border-radius: 10px;
    border-style: none;
    padding: 16px 24px;
    margin: 40px 0px;
}

.refund_title {
    font-size: 10px;
    color: rgb(125, 125, 125);
    margin-bottom: 6px;
}

.refund_subtitle {
    font-size: 10px;
    margin-top: 6px;
    color: rgb(125, 125, 125);
}

.refund_content {
    font-size: 10px;
    color: rgb(87, 87, 87);
}

.refund_group {
    margin-bottom: 40px;
    padding: 8px;
    border-radius: 10px;
    background-color: rgb(247, 247, 247);
}