.container {
    background-color: #f7fafa;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.container_inside {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.title_box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

p {
    font-size: 18px;
    color: black;
    font-family: var(--font-bold);
}

.group_name {
    font-size: 20px;
    color: black;
}

.student_list {
    font-size: 16px;
    color: darkgray;
}

.empty_msg {
    margin-top: 80px;
    margin-bottom: 100px;
    color: lightgray;
}