.container {
    background-color: #f7fafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
}

.container_inside {
    justify-content: left;
    background-color: white;
    border-radius: 20px;
    padding: 50px 100px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.back {
    display: flex;
    align-items: center;
}

.back:hover {
    cursor: pointer;
}

.back_arrow {
    margin-right: 10px;
}

.title {
    text-align: left;
    margin-top: 60px;
    color: rgb(185, 185, 185);
}

.desc {
    text-align: left;
    margin-top: 16px;
    font-size: 16px;
    color: rgb(72, 72, 72);
    margin-bottom: 50px;
}

.product_title {
    text-align: left;
    font-size: 18px;
    margin-bottom: 4px;
}

.product_desc {
    margin-top: 4px;
    text-align: left;
    line-height: 200%;
    font-size: 14px;
    color: rgb(157, 157, 157);
}

.pay_btn {
    background-color: #60e2ca;
    font-family: var(--font-bold);
    font-size: 20px;
    color: white;
    border-radius: 10px;
    border-style: none;
    padding: 16px 24px;
    margin: 80px 0px;
}

.money {
    font-size: 22px;
}

.active {
    background-color: rgb(247, 247, 247);
    border-color: #60e2ca;
    border-style: solid;
    border-width: 3px;
    margin-top: 16px;
    padding: 8px 24px;
    border-radius: 10px;
}

.active:hover {
    cursor: pointer;
}

.deactive {
    background-color: rgb(247, 247, 247);
    margin-top: 16px;
    padding: 8px 24px;
    border-radius: 10px;
    border-color: transparent;
    border-style: solid;
    border-width: 3px;
}