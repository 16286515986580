.parent {
    margin-top: 6px;
    margin-bottom: 6px;
}

.title {
    text-align: left;
    font-size: 14px;
    margin-right: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.type {
    background-color: #dbf8f3;
    padding: 6px;
    border-radius: 8px;
    font-size: 12px;
    font-family: var(--font-bold);
    color: #56d6bf;
}

.time {
    margin-top: 0px;
    text-align: left;
    font-size: 14px;
    color: lightgray;
}

.title_group {
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
}