.container {
    display: flex;
    background-color: white;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 16px;
    padding-bottom: 0px;
}

.container_inside {
    display: flex;
    background-color: white;
    flex-direction: row;
    justify-content: space-between;
}

.info_box {
    display: flex;
    flex-direction: column;
}

.status_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.name {
    font-family: var(--font-bold);
    color: black;
    font-size: 16px;
    margin-bottom: 0px;
    text-align: left;
}

.email {
    margin-top: 8px;
    text-align: left;
    font-family: var(--font-bold);
    color: darkgray;
    font-size: 12px;
}

.action_btn {
    color: #ff8c8c;
    font-family: var(--font-bold);
    background-color: white;
    border-style: none;
    font-size: 14px;
    cursor: pointer;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 0px;
}
