.header_top {
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-top: 6px;
    padding-right: 24px;
    background-color: #f7fafa;
    padding-bottom: 6px;
}

.brandGroup {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 60px;
    height: 60px;
}

.account {
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-family: var(--font-bold);
    color: darkgray;
}

.account_option {
    margin-left: 16px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: black;
}

.account_btn {
    display: flex;
    align-items: center;
    border-style: none;
    margin-left: 16px;
    background-color: #dbf8f3;
    padding: 12px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-family: var(--font-bold);
    color: #56d6bf;
}

.logout {
    height: 40px;
    display: flex;
    align-items: center;
    border-style: none;
    margin-left: 16px;
    background-color: #ffeaea;
    padding: 12px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-family: var(--font-bold);
    color: #f96666;
    margin-right: 32px;
}

.setting_btn {
    display: flex;
    align-items: center;
    border-style: none;
    margin-left: 16px;
    background-color: #dbf8f3;
    padding: 8px 10px;
    border-radius: 8px;
}

.account_txt {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    font-family: var(--font-bold);
    color: #56d6bf;
}

.gear {
    color: #56d6bf;
    margin-right: 6px;
}

.setting_btn:hover {
    cursor: pointer;
}

.brandGroup:hover {
    cursor: pointer;
}

.logout_group {
    display: flex;
    align-items: center;
}

@media only screen and (max-device-width : 480px) {
    .brand {
        display: none;
    }

    .account_option { 
        font-size: 12px;
    }

    .account_btn {
        font-size: 12px;
    }

    .setting_btn {
        font-size: 12px;
    }

    .account_txt {
        font-size: 12px;
    }
}