.title {
    text-align: left;
    margin-right: 400px;
    font-family: var(--font-bold);
    font-size: 20px;
    color: black;
}

.subtitle {
    font-family: var(--font-bold);
    text-align: left;
    font-size: 14px;
    color: rgb(113, 113, 113);
    margin-top: 0px;
    line-height: 180%;
    white-space: break-spaces;
}

.subtitle span {
    color: rgb(255, 50, 50);
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7fafa;
}

.container_inside {
    background-color: white;
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 60px;
    margin-bottom: 60px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.title_box {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin-top: 40px;
    margin-bottom: 40px;
}

.input_text {
    margin-bottom: 32px;
    border-radius: 10px;
    border-style: solid;
    border-color: lightgray;
    height: 300px;
    font-family: var(--font-bold);
    outline-color: #56d6bf;
    font-size: 16px;
    line-height: 150%;
    padding: 16px;
}

.done_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 50px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: white;
    margin-top: 32px;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}

.input_box {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.done_btn_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}