.conatainer {
    display: flex;
}

.container_inside {
    display: flex;
    flex-direction: column;
    padding: 50px 50px;
    background-color: white;
    border-radius: 20px;
    border-style: none;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
    margin-top: 80px;
    margin-bottom: 200px;
    margin-left: 80px;
    margin-right: 80px;
}

.top_menu {
    display: flex;
    justify-content: space-between;
}

.title_box {
    display: flex;
    align-items: center;
}

.title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px;
    font-family: var(--font-bold);
    color: black;
}

.gear {
    color: black;
    margin-right: 10px;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 40px;
}

.info_section {
    display: flex;
    align-items: center;
}

.section {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

.subtitle {
    text-align: left;
    font-size: 18px;
    color: black;
    margin-right: 24px;
}

.subtitle_box {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
}

.edit_btn {
    width: 60px;
    height: 30px;
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
    color: rgb(130, 130, 130);
    font-size: 12px;
    font-family: "bold";
    border-style: none;
}

.change_pw_btn {
    width: 90px;
    height: 30px;
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
    color: rgb(130, 130, 130);
    font-size: 12px;
    font-family: "bold";
    border-style: none;
    margin-left: 10px;
}

.info_title {
    text-align: left;
    width: 100px;
    font-size: 14px;
    color: darkgray;
    margin: 10px 0px;
}

.info {
    font-size: 16px;
    margin: 10px 0px;
}

.home {
    background-color: #dbf8f3;
    color: #56d6bf;
    padding: 10px;
    border-radius: 10px;
}

.home:hover {
    cursor: pointer;
}

.group_empty {
    text-align: left;
    color: lightgray;
    font-size: 16px;
}

.my_group {
    text-align: left;
    color: #56d6bf;
    font-size: 16px;
}