.parent {
    background-color: #f7fafa;
    text-align: left;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 60px;
    padding-right: 60px;
}

.text {
    font-family: var(--font-regular);
    font-size: 12px;
    color: lightgray;
}

.policy {
    display: flex;
    align-items: center;
}

.divider {
    margin: 0px 10px;
    font-family: var(--font-regular);
    font-size: 12px;
    color: lightgray;
}

.privacy {
    font-family: var(--font-regular);
    font-size: 12px;
    color: lightgray;
    text-decoration: underline;
}

.service {
    font-family: var(--font-regular);
    font-size: 12px;
    color: lightgray;
    text-decoration: underline;
}

.privacy:hover {
    cursor: pointer;
}

.service:hover {
    cursor: pointer;
}