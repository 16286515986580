.parent {
    display: flex;
    justify-content: center;
}

.parent_inside {
    display: flex;
    flex-direction: column;
    padding: 50px 80px;
    background-color: white;
    border-radius: 20px;
    border-style: none;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
    margin-top: 150px;
    margin-bottom: 200px;
}

.option_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.check {
    width: 24px;
    height: 24px;
    margin-left: 120px;
    accent-color: #56d6bf;
}

.hint {
    font-family: var(--font-bold);
    font-size: 16px;
    margin-top: 0px;
    text-align: left;
    color: rgb(201, 201, 201);
}

.title {
    text-align: left;
    font-family: var(--font-bold);
    font-size: 20px;
}

.subtitle {
    font-family: var(--font-bold);
    font-size: 16px;
    color: lightgray;
    text-align: left;
    margin-top: 60px;
    margin-bottom: 16px;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 40px;
    margin-bottom: -10px;
}

.request_parent {
    display: flex;
    justify-content: center;
}

.request_btn {
    margin-top: 70px;
    width: 120px;
    height: 50px;
    font-size: 16px;
    font-family: var(--font-bold);
    color: white;
    background-color: #56d6bf;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
}